import React, { useEffect, useState } from "react";
import Select, { SelectOption, SelectOptionGroup } from "@amzn/meridian/select";
import {
  ReviewResult,
  GroundTruthField,
  setFieldConfirmedAnswer,
} from "src/store/modelLifeCycleGroundTruthSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  getChildOptions,
  getConfirmedAnswer,
  getLabel,
  isAnswerInadequate,
  isAnswerMatch,
  isConfirmedAnswerValid,
  isFieldMultiSelected,
} from "src/components/validationWorkflow/groundTruthReview/modelGroundTruthReviewUtil";
import Row from "@amzn/meridian/row";
import { Field, FieldType } from "src/store/modelManagementSlice";
import Input from "@amzn/meridian/input";
import { createSearchRegExp } from "src/helpers";
import Button from "@amzn/meridian/button";
import "./reviewDecisionCells.scss";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import {
  compareStringsIgnoreCase,
  hasOverlapCaseInsensitive,
} from "src/utils/stringUtil";
import {
  INADEQUATE_INFORMATION,
  NO_OBJECT_INVOLVED,
} from "src/components/audit/helpers";

interface ReviewDecisionProps {
  groundTruthField: GroundTruthField;
  shouldShowError: boolean;
  fieldIndex: number;
}

const noObjectInvolvedSelection = (
  <SelectOptionGroup label={"Injury is attributed to repetitive motion"}>
    <SelectOption label={NO_OBJECT_INVOLVED} value={NO_OBJECT_INVOLVED} />
  </SelectOptionGroup>
);

const inadequateSelection = (
  <SelectOptionGroup label={"Answer not exist in the description"}>
    <SelectOption
      label={INADEQUATE_INFORMATION}
      value={INADEQUATE_INFORMATION}
    />
  </SelectOptionGroup>
);

const otherSelection = (
  <SelectOptionGroup label={"Answer not exist in the options"}>
    <SelectOption label={"Other"} value={"Other"} />
  </SelectOptionGroup>
);

const ReviewDecisionCells: React.FC<ReviewDecisionProps> = ({
  groundTruthField,
  shouldShowError,
  fieldIndex,
}) => {
  const dispatch = useAppDispatch();
  const { selectedModel } = useAppSelector((state) => state.modelSlice);
  const { showUserAnswerColumn, showLLMAnswerColumn, selectedGroundTruthData } =
    useAppSelector((state) => state.modelLifeCycleGroundTruthSlice);

  const [showFullOptionList, setShowFullOptionList] = useState(
    !showUserAnswerColumn && !showLLMAnswerColumn,
  );

  const [searchQuery, setSearchQuery] = useState("");
  const searchRegExp = createSearchRegExp(searchQuery);
  const getFullOptionList = (groundTruthField: GroundTruthField) => {
    const field = Object.values(selectedModel.fields).find(
      (label: Field) => label.name === groundTruthField.fieldName,
    );
    const many = field?.many || false;
    const isFreeTextType = field?.type === FieldType.freeText;

    const fieldOptions = field?.options || [];
    const hasOtherOption = fieldOptions.some((option) =>
      compareStringsIgnoreCase(option, "other"),
    );
    const hasNoObjectInvolved = fieldOptions.some((option) =>
      compareStringsIgnoreCase(option, "No object involved"),
    );
    const hasInadequateInfo = fieldOptions.some((option) =>
      compareStringsIgnoreCase(option, "inadequate information"),
    );

    const isChildField = selectedModel.hierarchy?.some(
      (h) => h.childFieldName === groundTruthField.fieldName,
    );

    let value = groundTruthField.confirmedAnswer;

    if (many) {
      if (typeof value === "string") {
        value = value.includes("|") ? value.split("|") : [value];
      }
    }
    if (isFreeTextType) {
      return (
        <Input
          value={
            typeof value === "string"
              ? value === ReviewResult.NEITHER
                ? ""
                : value
              : value.join(",")
          }
          onChange={(answer) => {
            dispatch(
              setFieldConfirmedAnswer({
                fieldIndex: fieldIndex,
                confirmedAnswer: answer,
                reviewResult: ReviewResult.NEITHER,
              }),
            );
          }}
          type="text"
          placeholder="Enter answer..."
        />
      );
    }

    // If this is a child field, we need to handle parent-child relationship
    if (isChildField && selectedModel.hierarchy) {
      const hierarchyRule = selectedModel.hierarchy.find(
        (h) => h.childFieldName === groundTruthField.fieldName,
      );

      if (hierarchyRule) {
        const parentField = selectedGroundTruthData.fields.find(
          (field) => field.fieldName === hierarchyRule.parentFieldName,
        );
        if (
          !parentField?.confirmedAnswer ||
          parentField?.confirmedAnswer.length === 0
        ) {
          return (
            <Text type={"b100"}>
              Please select {hierarchyRule.parentFieldName}
            </Text>
          );
        }

        const isParentSelectInadequateInfo = Array.isArray(
          parentField.confirmedAnswer,
        )
          ? parentField.confirmedAnswer.some((answer) =>
              compareStringsIgnoreCase(answer, INADEQUATE_INFORMATION),
            )
          : compareStringsIgnoreCase(
              parentField.confirmedAnswer,
              INADEQUATE_INFORMATION,
            );

        const isParentSelectNoObjectInvolved = Array.isArray(
          parentField.confirmedAnswer,
        )
          ? parentField.confirmedAnswer.some((answer) =>
              compareStringsIgnoreCase(answer, NO_OBJECT_INVOLVED),
            )
          : compareStringsIgnoreCase(
              parentField.confirmedAnswer,
              NO_OBJECT_INVOLVED,
            );

        if (isParentSelectInadequateInfo) {
          dispatch(
            setFieldConfirmedAnswer({
              fieldIndex: fieldIndex,
              confirmedAnswer: getConfirmedAnswer(
                groundTruthField,
                INADEQUATE_INFORMATION,
                isFieldMultiSelected(groundTruthField, selectedModel),
              ),
              reviewResult: [value],
            }),
          );
          return (
            <Select
              disabled={true}
              value={INADEQUATE_INFORMATION}
              size={"small"}
            >
              <SelectOption
                label={INADEQUATE_INFORMATION}
                value={INADEQUATE_INFORMATION}
              />
            </Select>
          );
        }

        if (isParentSelectNoObjectInvolved) {
          dispatch(
            setFieldConfirmedAnswer({
              fieldIndex: fieldIndex,
              confirmedAnswer: getConfirmedAnswer(
                groundTruthField,
                NO_OBJECT_INVOLVED,
                isFieldMultiSelected(groundTruthField, selectedModel),
              ),
              reviewResult: [value],
            }),
          );
          return (
            <Select disabled={true} value={NO_OBJECT_INVOLVED} size={"small"}>
              <SelectOption
                label={NO_OBJECT_INVOLVED}
                value={NO_OBJECT_INVOLVED}
              />
            </Select>
          );
        }

        // Get available options based on parent selection
        const availableOptions = getChildOptions(
          selectedModel.hierarchy,
          hierarchyRule.parentFieldName,
          groundTruthField.fieldName,
          parentField.confirmedAnswer,
          hasNoObjectInvolved,
          hasInadequateInfo,
          hasOtherOption,
        );
        return (
          <div>
            {Array.isArray(parentField.confirmedAnswer) ? (
              parentField.confirmedAnswer.map((parentValue: string) => {
                const childOptions = getChildOptions(
                  selectedModel.hierarchy,
                  hierarchyRule.parentFieldName,
                  groundTruthField.fieldName,
                  parentValue,
                  hasNoObjectInvolved,
                  hasInadequateInfo,
                  hasOtherOption,
                );
                return (
                  <Select
                    label={`Option for ${parentValue}`}
                    value={value}
                    onChange={(answer) => {
                      if (Array.isArray(answer)) {
                        answer = answer.filter((option: string) => option);
                      }
                      dispatch(
                        setFieldConfirmedAnswer({
                          fieldIndex: fieldIndex,
                          confirmedAnswer: answer,
                          reviewResult: ReviewResult.NEITHER,
                        }),
                      );
                    }}
                    placeholder="Select answer..."
                    size="small"
                    searchQuery={searchQuery}
                    onSearch={setSearchQuery}
                    error={
                      shouldShowError &&
                      (!isConfirmedAnswerValid(
                        groundTruthField.confirmedAnswer,
                      ) ||
                        !hasOverlapCaseInsensitive(
                          childOptions,
                          groundTruthField.confirmedAnswer,
                        ))
                    }
                    errorMessage={
                      shouldShowError &&
                      (!isConfirmedAnswerValid(
                        groundTruthField.confirmedAnswer,
                      ) ||
                        !hasOverlapCaseInsensitive(
                          childOptions,
                          groundTruthField.confirmedAnswer,
                        )) &&
                      "please select a valid answer"
                    }
                  >
                    {hasInadequateInfo && inadequateSelection}
                    {hasNoObjectInvolved && noObjectInvolvedSelection}
                    <SelectOptionGroup label={"Options"}>
                      {childOptions
                        .filter((option) => searchRegExp.test(option))
                        .filter(
                          (option) =>
                            !compareStringsIgnoreCase(
                              option,
                              INADEQUATE_INFORMATION,
                            ),
                        )
                        .filter(
                          (option) =>
                            !compareStringsIgnoreCase(option, "Other"),
                        )
                        .filter(
                          (option) =>
                            !compareStringsIgnoreCase(
                              option,
                              NO_OBJECT_INVOLVED,
                            ),
                        )
                        .map((option) => (
                          <SelectOption
                            key={option}
                            value={option}
                            label={option}
                          />
                        ))}
                    </SelectOptionGroup>
                    {hasOtherOption && otherSelection}
                  </Select>
                );
              })
            ) : (
              // Single parent selection
              <Select
                value={value}
                label={`Options for ${parentField.confirmedAnswer}`}
                onChange={(answer) => {
                  if (Array.isArray(answer)) {
                    answer = answer.filter((option: string) => option);
                  }
                  dispatch(
                    setFieldConfirmedAnswer({
                      fieldIndex: fieldIndex,
                      confirmedAnswer: answer,
                      reviewResult: ReviewResult.NEITHER,
                    }),
                  );
                }}
                placeholder="Select answer..."
                size="small"
                searchQuery={searchQuery}
                onSearch={setSearchQuery}
              >
                <SelectOptionGroup label="Options">
                  {availableOptions
                    .filter((option) => searchRegExp.test(option))
                    .filter(
                      (option) =>
                        !compareStringsIgnoreCase(
                          option,
                          INADEQUATE_INFORMATION,
                        ),
                    )
                    .filter(
                      (option) => !compareStringsIgnoreCase(option, "Other"),
                    )
                    .filter(
                      (option) =>
                        !compareStringsIgnoreCase(option, NO_OBJECT_INVOLVED),
                    )
                    .map((option) => (
                      <SelectOption
                        key={option}
                        value={option}
                        label={option}
                      />
                    ))}
                </SelectOptionGroup>
              </Select>
            )}
          </div>
        );
      }
    }
    return (
      <Select
        value={value}
        onChange={(answer) => {
          if (Array.isArray(answer)) {
            answer = answer.filter((option: string) => option);
          }
          dispatch(
            setFieldConfirmedAnswer({
              fieldIndex: fieldIndex,
              confirmedAnswer: answer,
              reviewResult: ReviewResult.NEITHER,
            }),
          );
          clearChildFields(groundTruthField.fieldName);
        }}
        placeholder={"Select answer..."}
        size="small"
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        error={
          shouldShowError &&
          !isConfirmedAnswerValid(groundTruthField.confirmedAnswer)
        }
        errorMessage={
          shouldShowError &&
          !isConfirmedAnswerValid(groundTruthField.confirmedAnswer) &&
          "please select a valid answer"
        }
      >
        {inadequateSelection}
        {hasNoObjectInvolved && noObjectInvolvedSelection}
        <SelectOptionGroup label={"Options"}>
          {Object.values(selectedModel.fields)
            .find((label: Field) => label.name === groundTruthField.fieldName)
            ?.options.filter((option) => searchRegExp.test(option))
            .filter(
              (option) =>
                !compareStringsIgnoreCase(option, INADEQUATE_INFORMATION),
            )
            .filter((option) => !compareStringsIgnoreCase(option, "Other"))
            .filter(
              (option) => !compareStringsIgnoreCase(option, NO_OBJECT_INVOLVED),
            )
            .map((option) => <SelectOption value={option} label={option} />)}
        </SelectOptionGroup>
        {hasOtherOption && otherSelection}
      </Select>
    );
  };

  const clearChildFields = (parentFieldName: string) => {
    if (!selectedModel.hierarchy) return;
    const childFields = selectedModel.hierarchy
      .filter((h) => h.parentFieldName === parentFieldName)
      .map((h) => h.childFieldName);

    childFields.forEach((childFieldName) => {
      const childFieldIndex = selectedGroundTruthData.fields.findIndex(
        (field) => field.fieldName === childFieldName,
      );

      if (childFieldIndex !== -1) {
        const childField = selectedGroundTruthData.fields[childFieldIndex];
        const isMultiSelect = isFieldMultiSelected(childField, selectedModel);

        dispatch(
          setFieldConfirmedAnswer({
            fieldIndex: childFieldIndex,
            confirmedAnswer: isMultiSelect ? [] : "",
            reviewResult: ReviewResult.NEITHER,
          }),
        );
      }
    });
  };
  useEffect(() => {
    if (groundTruthField?.reviewResult === ReviewResult.NOT_REVIEWED_YET) {
      dispatch(
        setFieldConfirmedAnswer({
          fieldIndex: fieldIndex,
          confirmedAnswer: groundTruthField.llmAnswer,
          reviewResult: ReviewResult.NOT_REVIEWED_YET,
        }),
      );
    }
  }, [groundTruthField, fieldIndex]);

  const getValue = (reviewResult: ReviewResult) => {
    if (reviewResult === ReviewResult.BOTH_CORRECT)
      return ReviewResult.BOTH_CORRECT;
    if (reviewResult === ReviewResult.NEITHER) return ReviewResult.NEITHER;
    if (reviewResult === ReviewResult.NOT_REVIEWED_YET)
      // if not reviewed for blinding is off, default to llmAnswer
      return groundTruthField.llmAnswer;
    return groundTruthField.confirmedAnswer;
  };

  return (
    <>
      <Row>
        <Column>
          {(showUserAnswerColumn || showLLMAnswerColumn) && (
            <Select
              width="200px"
              size={"small"}
              value={getValue(groundTruthField.reviewResult)}
              onChange={(value) => {
                if (value === ReviewResult.NEITHER) {
                  setShowFullOptionList(true);
                } else {
                  setShowFullOptionList(false);
                }
                dispatch(
                  setFieldConfirmedAnswer({
                    fieldIndex: fieldIndex,
                    confirmedAnswer: getConfirmedAnswer(
                      groundTruthField,
                      value,
                      isFieldMultiSelected(groundTruthField, selectedModel),
                    ),
                    reviewResult: value,
                  }),
                );
              }}
              placeholder="Choose output..."
              error={
                shouldShowError &&
                !isConfirmedAnswerValid(groundTruthField.confirmedAnswer)
              }
              errorMessage={
                shouldShowError &&
                !isConfirmedAnswerValid(groundTruthField.confirmedAnswer) &&
                "please select an answer"
              }
            >
              {!isAnswerInadequate(groundTruthField.llmAnswer) && (
                <SelectOption
                  key={groundTruthField.llmAnswer}
                  value={groundTruthField.llmAnswer}
                  label={getLabel(groundTruthField, ReviewResult.LLM_CORRECT)}
                />
              )}
              {!isAnswerMatch(
                groundTruthField,
                isFieldMultiSelected(groundTruthField, selectedModel),
              ) &&
                !isAnswerInadequate(groundTruthField.userAnswer) && (
                  <SelectOption
                    key={groundTruthField.userAnswer}
                    value={groundTruthField.userAnswer}
                    label={getLabel(
                      groundTruthField,
                      ReviewResult.HUMAN_CORRECT,
                    )}
                  />
                )}
              {!isAnswerInadequate(groundTruthField.llmAnswer) &&
                !isAnswerInadequate(groundTruthField.userAnswer) &&
                groundTruthField.llmAnswer !== groundTruthField.userAnswer && (
                  <SelectOption
                    key={ReviewResult.BOTH_CORRECT}
                    value={ReviewResult.BOTH_CORRECT}
                    label={getLabel(
                      groundTruthField,
                      ReviewResult.BOTH_CORRECT,
                    )}
                  />
                )}
              <SelectOption
                key={ReviewResult.INADEQUATE}
                value={ReviewResult.INADEQUATE}
                label={getLabel(groundTruthField, ReviewResult.INADEQUATE)}
              />
              <SelectOption
                key={ReviewResult.NEITHER}
                value={ReviewResult.NEITHER}
                label={getLabel(groundTruthField, ReviewResult.NEITHER)}
              />
            </Select>
          )}
          {showFullOptionList && getFullOptionList(groundTruthField)}
        </Column>
        <div className="confirmedAnswerContainer">
          {Array.isArray(groundTruthField.confirmedAnswer) &&
            groundTruthField.confirmedAnswer.length > 1 &&
            Object.values(groundTruthField.confirmedAnswer).map((answer) => (
              <div className="confirmedAnswer">
                <Button size={"small"} type={"tertiary"}>
                  {answer}
                </Button>
              </div>
            ))}
        </div>
      </Row>
    </>
  );
};

export default ReviewDecisionCells;
