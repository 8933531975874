import React, { useState, useEffect } from "react";
import Pie from "@amzn/meridian/pie";
import Legend, { LegendProvider } from "@amzn/meridian/legend";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Heading from "@amzn/meridian/heading";
import { useAppSelector } from "src/store/store";
import "./groundTruthReview/modelGroundTruthReview.scss";
import Loader from "@amzn/meridian/loader";
import { getRatio } from "src/components/audit/helpers";
import { ReviewStatus } from "src/store/modelLifeCycleGroundTruthSlice";

export interface DonutChartProps {
  key: string;
  value: number;
  color: string;
  label: string;
}

const DonutChart = () => {
  const widthRatio = 0.07;
  const [pieWidth, setPieWidth] = useState(window.innerWidth * widthRatio);

  useEffect(() => {
    const handleResize = () => {
      setPieWidth(window.innerWidth * widthRatio);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    groundTruthDataListView,
    getListViewLoading,
    groundTruthDataReviewedCount,
  } = useAppSelector((state) => state.modelLifeCycleGroundTruthSlice);

  const totalCount = groundTruthDataListView.length;

  const unReviewedCount = totalCount - groundTruthDataReviewedCount;

  const data: DonutChartProps[] = [
    {
      value: unReviewedCount,
      key: "unreviewed",
      color: "#D9D9D9",
      label: "Unreviewed",
    },
    {
      value: groundTruthDataReviewedCount,
      key: "reviewed",
      color: "#36c2b4",
      label: "Reviewed",
    },
  ];

  if (getListViewLoading === "pending") return <Loader></Loader>;

  return totalCount === 0 ? (
    <Text>Fail to extract ground truth</Text>
  ) : (
    <Row spacingInset={"400"} className="donut-chart">
      <LegendProvider data={data}>
        <Row spacing="400" alignmentHorizontal="center" alignmentVertical="top">
          <Pie width={pieWidth} data={data} donutRatio={0.7}>
            <Text type="h100" tag="div">
              {getRatio(data)}
            </Text>
            <Text type="b100" tag="div">
              unreviewed
            </Text>
          </Pie>
          <Column>
            <Heading id="animals-one" level={6}>
              {`Total ground truth:${totalCount}`}
            </Heading>
            <Text>Cases yet to be reviewed</Text>
            <Legend
              direction="vertical"
              values={(item) => item.value.toLocaleString()}
            />
          </Column>
        </Row>
      </LegendProvider>
    </Row>
  );
};

export default DonutChart;
