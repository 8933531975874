export const compareStringsIgnoreCase = (str1: string, str2: string) => {
  // Remove non-alphanumeric characters from the strings
  const cleanStr1 = str1.replace(/[^a-zA-Z0-9]/g, "");
  const cleanStr2 = str2.replace(/[^a-zA-Z0-9]/g, "");

  // Convert the cleaned strings to lowercase and compare
  return cleanStr1.trim().toLowerCase() === cleanStr2.trim().toLowerCase();
};

export const truncateText = (text: string, maxLength: number = 25): string => {
  // Return original text if it's shorter than maxLength
  if (text.length <= maxLength) {
    return text;
  }

  // Truncate and add ellipsis
  return `${text.slice(0, maxLength)}...`;
};

export const hasOverlapCaseInsensitive = (
  array1: string[],
  array2: string[] | string,
): boolean => {
  const arr2 = Array.isArray(array2) ? array2 : [array2];
  const normalizedArray2 = arr2.map((s) => s.toLowerCase());
  return array1.some((item) => normalizedArray2.includes(item.toLowerCase()));
};
