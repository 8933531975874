import apiClient from "src/utils/apiClient";
import {
  UploadNewRevisionPayload,
  ValidatePayload,
  GetGroundTruthsCSVPayload as DownloadGroundTruthsInCSVPayload,
} from "src/store/modelLifeCycleSlice";
import { success, successMedium } from "src/utils/toastHelper";
import { AuditData } from "src/store/auditSlice";
import store from "src/store/store";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";
import {
  GroundTruthData,
  ReviewEntry,
} from "src/store/modelLifeCycleGroundTruthSlice";

class ModelLifeCycleService {
  readonly MODEL_LIFE_CYCLE = "model_life_cycle"; // prefix

  async getCurrentGroundTruth(modelId: string) {
    return await apiClient.get(`/${this.MODEL_LIFE_CYCLE}/files`, {
      params: { model_id: modelId },
    });
  }

  async getHistoricalValidation(modelId: string, uploadId: string) {
    return await apiClient.get(`/${this.MODEL_LIFE_CYCLE}/history`, {
      params: { model_id: modelId, upload_id: uploadId },
    });
  }

  async uploadNewRevision(payload: UploadNewRevisionPayload) {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("model_id", payload.modelId);
    const res = await apiClient.post(
      `/${this.MODEL_LIFE_CYCLE}/upload`,
      formData,
    );
    if (res) {
      success("File has been successfully uploaded");
    }
    return res;
  }

  async validate({ modelId, uploadId }: ValidatePayload) {
    const res = await apiClient.post(
      `/${this.MODEL_LIFE_CYCLE}/validate`,
      null,
      {
        params: {
          model_id: modelId,
          upload_id: uploadId,
        },
      },
    );

    if (res) {
      successMedium(
        "The file has been sent for validation successfully. Please refresh the page later to view the validated result. Depending on the file size, it may take a few minutes to several hours.",
      );
    }
    return res;
  }

  async getGroundTruthDataListView(modelId: string) {
    return await apiClient.get(
      `/${this.MODEL_LIFE_CYCLE}/ground_truth_data_list`,
      {
        params: { model_id: modelId },
      },
    );
  }

  async getGroundTruthData(modelId: string, sourceId: string) {
    return await apiClient.get(`/${this.MODEL_LIFE_CYCLE}/ground_truth_data`, {
      params: { model_id: modelId, source_id: sourceId },
    });
  }

  async updateGroundTruthData(payload: GroundTruthData) {
    const res = await apiClient.put(
      `/${this.MODEL_LIFE_CYCLE}/update`,
      payload,
    );
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Updated ground truth data successfully.",
          timeout: TOAST_TIMEOUT,
        }),
      );
    }
    return res;
  }

  async addReviewEntry(
    modelId: string,
    sourceId: string,
    reviewEntry: ReviewEntry,
  ) {
    const res = await apiClient.put(
      `/${this.MODEL_LIFE_CYCLE}/review_entry`,
      reviewEntry,
      {
        params: {
          model_id: modelId,
          source_id: sourceId,
        },
      },
    );
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Updated ground truth data successfully.",
          timeout: TOAST_TIMEOUT,
        }),
      );
    }
    return res;
  }

  async downloadGroundTruthsInCSV(payload: DownloadGroundTruthsInCSVPayload) {
    const res = await apiClient.post(
      `/model_life_cycle/post_process?model_id=${payload.modelId}`,
      {}, // payload
      {
        responseType: "blob",
        timeout: 60000, // 60 seconds timeout
      },
    );

    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Download Ground Truths in csv format successfully.",
          timeout: TOAST_TIMEOUT,
        }),
      );
    }

    return res;
  }
}

export default new ModelLifeCycleService();
