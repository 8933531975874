import React from "react";
import { TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import { GroundTruthField } from "src/store/modelLifeCycleGroundTruthSlice";
import {
  getAnswerLowerCaseAndSeparatedByComma,
  isAnswerMatch,
  isFieldMultiSelected,
} from "src/components/validationWorkflow/groundTruthReview/modelGroundTruthReviewUtil";
import { useAppSelector } from "src/store/store";

interface AnswerCellsProps {
  isBlind: boolean;
  randomizedOrder: boolean;
  groundTruthField: GroundTruthField;
  showUserAnswerColumn: boolean;
  showLLMAnswerColumn: boolean;
}

const AnswerCells: React.FC<AnswerCellsProps> = ({
  isBlind,
  randomizedOrder,
  groundTruthField,
  showUserAnswerColumn,
  showLLMAnswerColumn,
}) => {
  const { selectedModel } = useAppSelector((state) => state.modelSlice);
  const textColor = isAnswerMatch(
    groundTruthField,
    isFieldMultiSelected(groundTruthField, selectedModel),
  )
    ? "primary"
    : "error";
  if (showUserAnswerColumn && showLLMAnswerColumn) {
    if (isBlind && randomizedOrder) {
      return (
        <>
          <TableCell width={"20%"}>
            <Text color={textColor}>
              {getAnswerLowerCaseAndSeparatedByComma(
                groundTruthField.llmAnswer,
              )}
            </Text>
          </TableCell>
          <TableCell width={"20%"}>
            <Text color={textColor}>
              {getAnswerLowerCaseAndSeparatedByComma(
                groundTruthField.userAnswer,
              )}
            </Text>
          </TableCell>
        </>
      );
    }

    return (
      <>
        <TableCell width={"20%"}>
          <Text color={textColor}>
            {getAnswerLowerCaseAndSeparatedByComma(groundTruthField.userAnswer)}
          </Text>
        </TableCell>
        <TableCell width={"20%"}>
          <Text color={textColor}>
            {getAnswerLowerCaseAndSeparatedByComma(groundTruthField.llmAnswer)}
          </Text>
        </TableCell>
      </>
    );
  } else if (showUserAnswerColumn) {
    return (
      <TableCell width={"20%"}>
        <Text color={textColor}>
          {getAnswerLowerCaseAndSeparatedByComma(groundTruthField.userAnswer)}
        </Text>
      </TableCell>
    );
  } else if (showLLMAnswerColumn) {
    return (
      <TableCell width={"20%"}>
        <Text color={textColor}>
          {getAnswerLowerCaseAndSeparatedByComma(groundTruthField.llmAnswer)}
        </Text>
      </TableCell>
    );
  } else {
    return <></>;
  }
};

export default AnswerCells;
