import {
  GroundTruthData,
  GroundTruthField,
  ReviewResult,
  ReviewStatus,
} from "src/store/modelLifeCycleGroundTruthSlice";
import { LabelHierarchy, Model } from "src/store/modelManagementSlice";
import { compareStringsIgnoreCase } from "src/utils/stringUtil";
import {
  INADEQUATE_INFORMATION,
  isMultipleAnswerEqual,
  NO_OBJECT_INVOLVED,
} from "src/components/audit/helpers";

export const getAnswerLowerCaseAndSeparatedByComma = (answer: string) => {
  if (answer.includes("|")) {
    return answer.split("|").join(", ");
  }
  return answer.toLocaleLowerCase();
};

export const isFieldMultiSelected = (
  groundTruthField: GroundTruthField,
  model: Model,
) => {
  return (
    Object.values(model.fields).find(
      (field) => field.name === groundTruthField.fieldName,
    )?.many || false
  );
};

export const getConfirmedAnswer = (
  groundTruthField: GroundTruthField,
  value: string,
  many: boolean,
) => {
  if (value === ReviewResult.BOTH_CORRECT) {
    return groundTruthField.llmAnswer;
  } else if (value === ReviewResult.NEITHER) {
    return many ? [] : ReviewResult.NEITHER;
  } else {
    return value;
  }
};

export const isAnswerInadequate = (answer: string) => {
  return (
    !answer ||
    compareStringsIgnoreCase(answer, "none") ||
    compareStringsIgnoreCase(answer, INADEQUATE_INFORMATION)
  );
};

export const isConfirmedAnswerValid = (answer: string | string[]) => {
  if (!Array.isArray(answer)) {
    return answer !== "" && !answer.toLowerCase().includes("neither");
  }

  if (answer.length === 0) return false;

  const hasInadequateInfo = answer.some((item) =>
    compareStringsIgnoreCase(item, INADEQUATE_INFORMATION),
  );

  const hasNoObjectInvolved = answer.some((item) =>
    compareStringsIgnoreCase(item, NO_OBJECT_INVOLVED),
  );

  // If has "inadequate information", array length must be 1
  if (hasInadequateInfo || hasNoObjectInvolved) {
    return answer.length === 1;
  }

  // For other cases, just check if array is not empty and doesn't contain "neither"
  return answer.every(
    (item) => item && !item.toLowerCase().includes("neither"),
  );
};

export const isAnswerMatch = (field: GroundTruthField, many: boolean) => {
  if (
    isAnswerInadequate(field.userAnswer) ||
    isAnswerInadequate(field.llmAnswer)
  ) {
    return false;
  }
  return (
    compareStringsIgnoreCase(field.userAnswer, field.llmAnswer) ||
    (many && isMultipleAnswerEqual(field.userAnswer, field.llmAnswer))
  );
};

export const getLabel = (
  groundTruthField: GroundTruthField,
  type: ReviewResult,
): string => {
  switch (type) {
    case ReviewResult.BOTH_CORRECT:
      return ReviewResult.BOTH_CORRECT;
    case ReviewResult.LLM_CORRECT:
      return getAnswerLowerCaseAndSeparatedByComma(groundTruthField.llmAnswer);
    case ReviewResult.INADEQUATE:
      return INADEQUATE_INFORMATION;
    case ReviewResult.HUMAN_CORRECT:
      return `${getAnswerLowerCaseAndSeparatedByComma(groundTruthField.userAnswer)}`;
    default:
      return "Others (Expand full list)";
  }
};

export const getChildOptions = (
  hierarchy: LabelHierarchy[] | undefined,
  parentFieldName: string,
  childFieldName: string,
  parentValue: string | string[],
  hasNoObjectInvolved: boolean,
  hasInadequateInfo: boolean,
  hasOther: boolean,
): string[] => {
  if (!hierarchy) return [];
  const hierarchyRule = hierarchy.find(
    (h) =>
      h.parentFieldName === parentFieldName &&
      h.childFieldName === childFieldName,
  );

  if (!hierarchyRule) return [];

  let baseOptions: string[] = [];

  if (Array.isArray(parentValue)) {
    baseOptions = parentValue.reduce((acc: string[], value: string) => {
      const options = hierarchyRule.options[value] || [];
      return [...acc, ...options];
    }, []);
  } else {
    baseOptions = hierarchyRule.options[parentValue] || [];
  }

  // Create a new array with all options
  return [
    ...baseOptions,
    ...(hasNoObjectInvolved ? [NO_OBJECT_INVOLVED] : []),
    ...(hasInadequateInfo ? [INADEQUATE_INFORMATION] : []),
    ...(hasOther ? ["Other"] : []),
  ];
};

export const getGroundTruthDataReviewStatus = (
  groundTruthData: GroundTruthData,
  alias: string,
) => {
  return groundTruthData.reviewContributors?.includes(alias) ||
    groundTruthData.status == ReviewStatus.REVIEWED
    ? ReviewStatus.REVIEWED
    : ReviewStatus.UNREVIEWED;
};
