import React, { useEffect, useRef, useState } from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import Divider from "@amzn/meridian/divider";
import Button from "@amzn/meridian/button";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";
import Icon from "@amzn/meridian/icon";
import chevronLeftLargeToken from "@amzn/meridian-tokens/base/icon/chevron-left-large";
import chevronRightLargeToken from "@amzn/meridian-tokens/base/icon/chevron-right-large";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  getSiteLocationMapping,
  resetGetSiteLocationMappingLoading,
  setSelectedModelId,
} from "src/store/modelManagementSlice";
import meridianColors from "@amzn/meridian-tokens/base/color";
import { getClarification } from "src/store/dataExtractionSlice";
import "./modelGroundTruthReview.scss";
import {
  highlightText,
  moveToTextTop,
  removeHighlightedText,
} from "src/components/audit/helpers";
import Loader from "@amzn/meridian/loader";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";
import {
  getGroundTruthData,
  getGroundTruthDataListView,
  GroundTruthDataListView,
  ReviewStatus,
  updateGroundTruthData,
  resetGroundTruthReviewPage,
  translate,
  setShowTranslation,
  setFieldIndex,
  addReviewEntry,
  updateConfirmedAnswersFromHistory,
  sortAndFilterGroundTruthDataListView,
} from "src/store/modelLifeCycleGroundTruthSlice";
import AnswerCells from "src/components/validationWorkflow/groundTruthReview/answerCells";
import {
  getGroundTruthDataReviewStatus,
  isConfirmedAnswerValid,
} from "src/components/validationWorkflow/groundTruthReview/modelGroundTruthReviewUtil";
import ReviewDecisionCells from "src/components/validationWorkflow/groundTruthReview/reviewDecisionCells";

const ModelGroundTruthReview = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { token, alias } = useAppSelector((state) => state.user);
  const {
    groundTruthDataListView,
    selectedGroundTruthData,
    getListViewLoading,
    updateGroundTruthDataLoading,
    getGroundTruthDataLoading,
    isBlind,
    showUserAnswerColumn,
    showLLMAnswerColumn,
    showTranslation,
    translation,
    translationLoading,
    groundTruthListIndex,
    pageLoadTimestamp,
  } = useAppSelector((state) => state.modelLifeCycleGroundTruthSlice);
  const { selectedModel, getModelByIdLoading, getSiteLocationMappingLoading } =
    useAppSelector((state) => state.modelSlice);

  const { modelId, sourceId } = useParams();

  const [shouldShowError, setShouldShowError] = useState(false);
  const [doesClarificationExist, setDoesClarificationExist] = useState(true);

  useEffect(() => {
    if (token && modelId && sourceId) {
      dispatch(getGroundTruthData({ modelId: modelId, sourceId: sourceId }));
    }
  }, [modelId, sourceId, token]);

  useEffect(() => {
    if (token && modelId && getListViewLoading === "idle") {
      dispatch(getGroundTruthDataListView(modelId));
    }
  }, [modelId, token, getListViewLoading]);

  useEffect(() => {
    if (modelId) {
      dispatch(setSelectedModelId(modelId));
    }
  }, [modelId]);

  useEffect(() => {
    if (getListViewLoading === "rejected") {
      navigate(`/validationWorkflow/${modelId}`);
    } else if (
      getListViewLoading === "fulfilled" &&
      selectedModel.id &&
      sourceId
    ) {
      dispatch(
        sortAndFilterGroundTruthDataListView({
          model: selectedModel,
          alias: alias,
        }),
      );
    }
  }, [getListViewLoading, selectedModel, sourceId]);

  useEffect(() => {
    if (updateGroundTruthDataLoading === "fulfilled" && sourceId) {
      dispatch(resetGroundTruthReviewPage());
      dispatch(resetGetSiteLocationMappingLoading());
      const next = findNextUnreviewedCase(groundTruthDataListView, sourceId);
      if (next) {
        navigate(`/validationWorkflow/${modelId}/${next.sourceId}`);
      } else {
        navigate(`/validationWorkflow/${modelId}`);
        dispatch(
          createToast({
            type: "success",
            message:
              "All ground truth have finished reviewed, redirect to list view page...",
            timeout: TOAST_TIMEOUT,
          }),
        );
      }
    }
  }, [updateGroundTruthDataLoading, sourceId]);

  const previousModelId = useRef<string>("");

  useEffect(() => {
    if (
      getGroundTruthDataLoading === "fulfilled" &&
      previousModelId.current !== selectedGroundTruthData.modelId
    ) {
      previousModelId.current = selectedGroundTruthData.modelId;
    }
  }, [getGroundTruthDataLoading, selectedGroundTruthData]);

  useEffect(() => {
    if (getGroundTruthDataLoading === "fulfilled") {
      dispatch(updateConfirmedAnswersFromHistory(alias));
    }
  }, [getGroundTruthDataLoading]);

  useEffect(() => {
    if (
      getListViewLoading === "fulfilled" &&
      getGroundTruthDataLoading === "fulfilled"
    ) {
      dispatch(setFieldIndex(sourceId));
    }
  }, [getListViewLoading, getGroundTruthDataLoading]);

  useEffect(() => {
    if (
      getModelByIdLoading === "fulfilled" &&
      getGroundTruthDataLoading === "fulfilled" &&
      selectedGroundTruthData.metadata?.siteCode &&
      getSiteLocationMappingLoading === "pending"
    ) {
      dispatch(
        getSiteLocationMapping(selectedGroundTruthData.metadata.siteCode),
      );
    }
  }, [selectedModel, getGroundTruthDataLoading]);

  const [randomizedFieldOutputOrders, setRandomizedFieldOutputOrders] =
    useState<boolean[]>([]);

  useEffect(() => {
    // for each filed, generate a random true/false value, example result list can be [true, false, ...]
    setRandomizedFieldOutputOrders(
      selectedGroundTruthData.fields.map(() => Math.random() < 0.5),
    );
  }, []);

  const findNextUnreviewedCase = (
    list: GroundTruthDataListView[],
    sourceId?: string,
  ) => {
    const currentIndex = sourceId
      ? list.findIndex((item) => item.sourceId === sourceId)
      : -1;

    return (
      // Try to find unreviewed case after current index
      (currentIndex !== -1 &&
        list
          .slice(currentIndex + 1)
          .find((item) => item.status === ReviewStatus.UNREVIEWED)) ||
      // If not found, find any unreviewed case excluding current sourceId
      list
        .filter((item) => item.sourceId !== sourceId)
        .find((item) => item.status === ReviewStatus.UNREVIEWED)
    );
  };

  const saveGroundTruthDataButton = () => {
    {
      return updateGroundTruthDataLoading === "pending" ? (
        <Button size="large">
          <Loader size={"small"} />
        </Button>
      ) : (
        <Button
          size="large"
          onClick={() => {
            if (
              !selectedGroundTruthData.fields.some(
                (groundTruthfield) =>
                  !isConfirmedAnswerValid(groundTruthfield.confirmedAnswer),
              )
            ) {
              if (selectedModel.requiredReviewerCount) {
                dispatch(
                  addReviewEntry({
                    groundTruthData: selectedGroundTruthData,
                    reviewer: alias,
                    pageLoadTimestamp: pageLoadTimestamp,
                  }),
                );
              } else {
                dispatch(
                  updateGroundTruthData({
                    groundTruthData: selectedGroundTruthData,
                    reviewer: alias,
                    pageLoadTimestamp: pageLoadTimestamp,
                  }),
                );
              }
              setShouldShowError(false);
            } else {
              setShouldShowError(true);
            }
          }}
          data-cy={"save-and-next__button"}
        >
          Save & Next
        </Button>
      );
    }
  };

  const getAnswerHeader = (isBlind: boolean) => {
    if (showUserAnswerColumn && showLLMAnswerColumn) {
      return (
        <>
          <TableCell width={"20%"}>
            <Text type="h200">{isBlind ? "Source 1" : "User Output"}</Text>
          </TableCell>
          <TableCell width={"20%"}>
            <Text type="h200">{isBlind ? "Source 2" : "LLM Output"}</Text>
          </TableCell>
        </>
      );
    } else if (showUserAnswerColumn) {
      return (
        <TableCell width={"20%"}>
          <Text type="h200">User Output</Text>
        </TableCell>
      );
    } else if (showLLMAnswerColumn) {
      return (
        <TableCell width={"20%"}>
          <Text type="h200">LLM Output</Text>
        </TableCell>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Column spacingInset={"400"} spacing={"none"}>
      <BreadcrumbGroup>
        <Breadcrumb onClick={() => navigate(`/validationWorkflow`)}>
          Validation workflow
        </Breadcrumb>
        <Breadcrumb onClick={() => navigate(`/validationWorkflow/${modelId}`)}>
          {selectedModel.name} - Processed
        </Breadcrumb>
        <Breadcrumb>{sourceId}</Breadcrumb>
      </BreadcrumbGroup>
      <Row widths={["80%", "20%"]}>
        <Row spacing={"400"}>
          <div
            onClick={() => {
              if (groundTruthDataListView[groundTruthListIndex - 1]) {
                navigate(
                  `/validationWorkflow/${modelId}/${groundTruthDataListView[groundTruthListIndex - 1].sourceId}`,
                );
              } else {
                navigate(`/validationWorkflow/${modelId}`);
              }
            }}
          >
            <Column spacingInset={"300"} className="icon">
              <Icon tokens={chevronLeftLargeToken} />
            </Column>
          </div>
          <div
            onClick={() => {
              dispatch(resetGroundTruthReviewPage());
              dispatch(resetGetSiteLocationMappingLoading());
              if (groundTruthDataListView[groundTruthListIndex + 1]) {
                navigate(
                  `/validationWorkflow/${modelId}/${groundTruthDataListView[groundTruthListIndex + 1].sourceId}`,
                );
              } else {
                navigate(`/validationWorkflow/${modelId}`);
              }
            }}
          >
            <Column spacingInset={"300"} className="icon">
              <Icon tokens={chevronRightLargeToken} />
            </Column>
          </div>
          <Text type="h500">
            <p className="weight">
              Source ID: {selectedGroundTruthData.sourceId}
            </p>
          </Text>
          <Text>
            <p className="weight">
              Status:{" "}
              {getGroundTruthDataReviewStatus(selectedGroundTruthData, alias)}
            </p>
          </Text>
          {selectedGroundTruthData.metadata?.siteCode && (
            <Text>
              <p className="weight">
                Site: {selectedGroundTruthData.metadata?.siteCode}
              </p>
            </Text>
          )}
          {selectedGroundTruthData.status === ReviewStatus.REVIEWED && (
            <Text>
              <p className="weight">
                Reviewed by: {selectedGroundTruthData.reviewer}
              </p>
            </Text>
          )}
        </Row>
        <Row alignmentHorizontal="end" widths={["60%", "40%"]}>
          <Row widths={"fill"} alignmentHorizontal="center">
            {saveGroundTruthDataButton()}
          </Row>
        </Row>
      </Row>
      <Divider></Divider>
      <Row
        widths={["35%", "fit", "65%"]}
        alignmentVertical="top"
        spacing={"none"}
      >
        <Column height="76vh" overflowY="auto">
          <div className="container">
            <div className="top-section">
              <Row>
                <div className={"button"}>
                  {translationLoading === "pending" ? (
                    <Loader size={"small"}></Loader>
                  ) : showTranslation ? (
                    <Button
                      size={"small"}
                      onClick={() => dispatch(setShowTranslation(false))}
                      type={"secondary"}
                    >
                      See original source text
                    </Button>
                  ) : (
                    <Button
                      size={"small"}
                      onClick={() =>
                        dispatch(
                          translate({
                            text: selectedGroundTruthData.sourceText,
                          }),
                        )
                      }
                    >
                      Translate to English
                    </Button>
                  )}
                </div>
              </Row>
              <Text
                id="incident-description-container"
                fontFamily="amazonEmber"
                type="b500"
                color="primary"
              >
                <div className="text-content">
                  {showLLMAnswerColumn && !doesClarificationExist && (
                    <Column spacingInset={"500 0 0 0"}>
                      <Alert size={"medium"} type="warning">
                        No phrase identified
                      </Alert>
                    </Column>
                  )}
                  {showTranslation && (
                    <Column spacingInset={"500 0 0 0"}>
                      <Alert size={"medium"} type="warning">
                        Please use translation only for reference, Excavator
                        will extraction base on original text content
                      </Alert>
                    </Column>
                  )}
                  {showTranslation ? (
                    <p id="incident-description" className="paragraph-style">
                      {translation}
                    </p>
                  ) : (
                    <p id="incident-description" className="paragraph-style">
                      {selectedGroundTruthData.sourceText}
                    </p>
                  )}
                </div>
              </Text>
            </div>
          </div>
        </Column>
        <Column className="audit-review-split-line"></Column>
        <Column height="76vh" overflowY="auto">
          <Table
            showDividers={true}
            fixHeaderRows={true}
            fixHeaderRowsFullWidth={true}
            headerRows={1}
          >
            <TableRow backgroundColor={meridianColors.colorGray50}>
              <TableCell width={"1%"}></TableCell>
              <TableCell width={"20%"}>
                <Text type="h200">Fields</Text>
              </TableCell>
              {getAnswerHeader(isBlind)}
              <TableCell width={"100%"} alignmentHorizontal={"start"}>
                <Text type="h200">Review Decision</Text>
              </TableCell>
            </TableRow>
            {selectedGroundTruthData.fields.map((groundTruthField, index) => {
              return (
                <TableRow
                  highlightOnHover
                  onMouseEnter={() => {
                    // set timeout to solve the UI mismatch caused by
                    // clarificationExist variable compete when hover mouse enter and leave
                    setTimeout(() => {
                      if (groundTruthField.llmClarification.length > 0) {
                        highlightText(
                          getClarification(groundTruthField.llmClarification),
                        );
                      } else {
                        moveToTextTop();
                        setDoesClarificationExist(false);
                      }
                    }, 100);
                  }}
                  onMouseLeave={() => {
                    setDoesClarificationExist(true);
                    removeHighlightedText();
                  }}
                >
                  <TableCell width={"1%"}></TableCell>
                  <TableCell width={"20%"}>
                    {groundTruthField.fieldName}
                  </TableCell>
                  <AnswerCells
                    isBlind={isBlind}
                    randomizedOrder={randomizedFieldOutputOrders[index]}
                    groundTruthField={groundTruthField}
                    showUserAnswerColumn={showUserAnswerColumn}
                    showLLMAnswerColumn={showLLMAnswerColumn}
                  />
                  <TableCell width={"fill"} alignmentHorizontal={"center"}>
                    <ReviewDecisionCells
                      groundTruthField={groundTruthField}
                      shouldShowError={shouldShowError}
                      fieldIndex={index}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </Column>
      </Row>
    </Column>
  );
};

export default ModelGroundTruthReview;
