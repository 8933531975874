import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LOADING_STATE } from "src/types/constants";
import ModelLifeCycleService from "src/services/modelLifeCycleService";
import { createFileUrl, downloadFile } from "src/utils/fileUtil";
import CommonService, { TranslationPayload } from "src/services/commonService";
import { compareStringsIgnoreCase } from "src/utils/stringUtil";
import { generateTimestamp } from "src/utils/dateUtil";

export enum ReviewStatus {
  REVIEWED = "Reviewed",
  UNREVIEWED = "Unreviewed",
}

export enum ReviewResult {
  LLM_CORRECT = "LLM is Correct",
  HUMAN_CORRECT = "Human is correct",
  INADEQUATE = "Inadequate Information",
  NEITHER = "Neither is correct",
  BOTH_CORRECT = "Both are correct",
  NOT_REVIEWED_YET = "Not reviewed yet",
}

export type ReviewFieldEntry = {
  fieldName: string;
  answer: string[];
};

export type ReviewEntry = {
  reviewer: string;
  reviewStartedAt: string;
  reviewSubmittedAt: string;
  fields: ReviewFieldEntry[];
};

export type GroundTruthField = {
  fieldName: string;
  userAnswer: string;
  llmClarification: string;
  llmAnswer: string;
  confirmedAnswer: string | string[];
  reviewResult: ReviewResult;
};

export type GroundTruthData = {
  modelId: string;
  sourceId: string;
  sourceText: string;
  fields: GroundTruthField[];
  timestamp: string;
  status: ReviewStatus;
  reviewer: string;
  reviewContributors: string[];
  reviewHistory: ReviewEntry[];
  metadata?: Record<string, any>;
};

export type GroundTruthDataListView = {
  sourceId: string;
  status: ReviewStatus;
  reviewer: string;
  reviewContributors: string[];
};

const MULTI_OPTION_DELIMITER = "|";

export type GroundTruthState = {
  groundTruthListIndex: number;
  groundTruthDataListView: GroundTruthDataListView[];
  groundTruthDataTotalCount: number;
  groundTruthDataReviewedCount: number;
  selectedGroundTruthData: GroundTruthData;
  itemsPerPage: number;
  currentPage: number;
  isBlind: boolean;
  showUserAnswerColumn: boolean;
  showLLMAnswerColumn: boolean;
  groundTruthDataFieldIndex: number;
  translation: string;
  showTranslation: boolean;
  pageLoadTimestamp: string;
  getListViewLoading: LOADING_STATE;
  getGroundTruthDataLoading: LOADING_STATE;
  getGroundTruthDataStatistics: LOADING_STATE;
  updateGroundTruthDataLoading: LOADING_STATE;
  translationLoading: LOADING_STATE;
};

export const initialGroundTruthData: GroundTruthData = {
  sourceId: "",
  modelId: "",
  sourceText: "",
  fields: [],
  timestamp: "",
  status: ReviewStatus.UNREVIEWED,
  reviewer: "",
  reviewContributors: [],
  reviewHistory: [],
};

const initialState: GroundTruthState = {
  groundTruthListIndex: -1,
  groundTruthDataListView: [],
  groundTruthDataTotalCount: 0,
  groundTruthDataReviewedCount: 0,
  selectedGroundTruthData: initialGroundTruthData,
  itemsPerPage: 50,
  currentPage: 1,
  isBlind: true,
  showUserAnswerColumn: true,
  showLLMAnswerColumn: true,
  groundTruthDataFieldIndex: -1,
  translation: "",
  showTranslation: false,
  getListViewLoading: "idle",
  getGroundTruthDataLoading: "idle",
  updateGroundTruthDataLoading: "idle",
  getGroundTruthDataStatistics: "idle",
  translationLoading: "idle",
  pageLoadTimestamp: "",
};

export const GROUND_TRUTH_UPLOAD_URL = "/model_life_cycle/upload";

export const getGroundTruthDataListView = createAsyncThunk(
  "modelLifeCycle/groundTruth/getGroundTruthDataListView",
  async (modelId: string) => {
    const { data } =
      await ModelLifeCycleService.getGroundTruthDataListView(modelId);
    return data;
  },
);

export const getGroundTruthData = createAsyncThunk(
  "modelLifeCycle/groundTruth/getGroundTruthData",
  async (payload: { modelId: string; sourceId: string }) => {
    const { data } = await ModelLifeCycleService.getGroundTruthData(
      payload.modelId,
      payload.sourceId,
    );
    return data;
  },
);

export const downloadGroundTruthsInCSV = createAsyncThunk(
  "modelLifeCycle/getetGroundTruthsCSV",
  async (payload: { modelId: string }) => {
    const { data } =
      await ModelLifeCycleService.downloadGroundTruthsInCSV(payload);
    return data;
  },
);

// trigger when regular update ground truth to record review history
const addReviewHistory = (payload: {
  groundTruthData: GroundTruthData;
  reviewer: string;
  pageLoadTimestamp: string;
}): GroundTruthData => {
  const newReviewEntry: ReviewEntry = {
    reviewer: payload.reviewer,
    reviewStartedAt: payload.pageLoadTimestamp,
    reviewSubmittedAt: generateTimestamp(),
    fields: payload.groundTruthData.fields.map(toReviewFieldEntry),
  };

  const currentReviewHistory = payload.groundTruthData.reviewHistory || [];

  return {
    ...payload.groundTruthData,
    reviewHistory: [...currentReviewHistory, newReviewEntry],
  };
};

export const updateGroundTruthData = createAsyncThunk(
  "modelLifeCycle/groundTruth/updateGroundTruthData",
  async (payload: {
    groundTruthData: GroundTruthData;
    reviewer: string;
    pageLoadTimestamp: string;
  }) => {
    payload.groundTruthData = addReviewHistory(payload);
    const { data } = await ModelLifeCycleService.updateGroundTruthData({
      ...payload.groundTruthData,
      fields: payload.groundTruthData.fields.map(
        (groundTruthField: GroundTruthField) =>
          toGroundTruthFields(groundTruthField),
      ),
      reviewer: payload.reviewer,
    });
    return data;
  },
);

export const addReviewEntry = createAsyncThunk(
  "modelLifeCycle/groundTruth/addReviewEntry",
  async (payload: {
    groundTruthData: GroundTruthData;
    pageLoadTimestamp: string;
    reviewer: string;
  }) => {
    const { data } = await ModelLifeCycleService.addReviewEntry(
      payload.groundTruthData.modelId,
      payload.groundTruthData.sourceId,
      {
        fields: payload.groundTruthData.fields.map(
          (groundTruthField: GroundTruthField) =>
            toReviewFieldEntry(groundTruthField),
        ),
        reviewer: payload.reviewer, // this will be handel by backend
        reviewStartedAt: payload.pageLoadTimestamp,
        reviewSubmittedAt: generateTimestamp(),
      },
    );
    return data;
  },
);

export const translate = createAsyncThunk(
  "model/translation",
  async (payload: TranslationPayload) => {
    const { data } = await CommonService.translate(payload);
    return data;
  },
);

export const toGroundTruthFields = (item: GroundTruthField) => {
  return {
    ...item,
    confirmedAnswer:
      typeof item.confirmedAnswer === "string"
        ? item.confirmedAnswer
        : item.confirmedAnswer.join(MULTI_OPTION_DELIMITER),
  };
};

export const toReviewFieldEntry = (groundTruthField: GroundTruthField) => {
  return {
    fieldName: groundTruthField.fieldName,
    answer:
      typeof groundTruthField.confirmedAnswer === "string"
        ? [groundTruthField.confirmedAnswer]
        : groundTruthField.confirmedAnswer,
  };
};

export const setLLMAnswerForUnreviewedGroundTruthData = (
  groundTruthData: GroundTruthData,
) => {
  if (groundTruthData.status === ReviewStatus.UNREVIEWED) {
    groundTruthData.fields.forEach((groundTruthField) => {
      groundTruthField.reviewResult = ReviewResult.NOT_REVIEWED_YET;
      if (
        compareStringsIgnoreCase(
          groundTruthField.llmAnswer,
          groundTruthField.userAnswer,
        )
      ) {
        groundTruthField.confirmedAnswer = groundTruthField.llmAnswer;
      } else {
        groundTruthField.confirmedAnswer = "";
      }
    });
  }
  return groundTruthData;
};

/** Data Extraction Page Slice */
const { reducer, actions } = createSlice({
  name: "modelLifeCycle/groundTruthReviewPageSlice",
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setIsBlind: (state, { payload }) => {
      state.isBlind = payload;
    },
    setItemPerPage: (state, { payload }) => {
      state.itemsPerPage = payload;
    },
    setFieldIndex: (state, { payload }) => {
      state.groundTruthListIndex = state.groundTruthDataListView.findIndex(
        (groundTruthDataView) => groundTruthDataView.sourceId === payload,
      );
    },
    setShowTranslation: (state, { payload }) => {
      state.showTranslation = payload;
    },
    setTranslation: (state, { payload }) => {
      state.translation = payload;
    },
    setFieldConfirmedAnswer: (state, { payload }) => {
      state.selectedGroundTruthData.fields[payload.fieldIndex].confirmedAnswer =
        payload.confirmedAnswer;
      if (
        payload.reviewResult === ReviewResult.BOTH_CORRECT ||
        payload.reviewResult === ReviewResult.INADEQUATE
      ) {
        state.selectedGroundTruthData.fields[payload.fieldIndex].reviewResult =
          payload.reviewResult;
      } else {
        if (
          payload.confirmedAnswer ===
          state.selectedGroundTruthData.fields[payload.fieldIndex].llmAnswer
        ) {
          state.selectedGroundTruthData.fields[
            payload.fieldIndex
          ].reviewResult = ReviewResult.LLM_CORRECT;
        } else if (
          payload.confirmedAnswer ===
          state.selectedGroundTruthData.fields[payload.fieldIndex].userAnswer
        ) {
          state.selectedGroundTruthData.fields[
            payload.fieldIndex
          ].reviewResult = ReviewResult.HUMAN_CORRECT;
        } else {
          state.selectedGroundTruthData.fields[
            payload.fieldIndex
          ].reviewResult = ReviewResult.NEITHER;
        }
      }
    },
    updateConfirmedAnswersFromHistory: (state, { payload }) => {
      const alias = payload;

      // Only process if status is UNREVIEWED or has reviewHistory
      if (
        state.selectedGroundTruthData.status === ReviewStatus.REVIEWED ||
        !state.selectedGroundTruthData.reviewHistory
      ) {
        return;
      }
      // Get review entries for the current reviewer
      const currentReviewerEntries =
        state.selectedGroundTruthData.reviewHistory?.filter(
          (entry) => entry.reviewer === alias,
        );

      // If no previous entries from this reviewer, leave confirmedAnswer as is
      if (!currentReviewerEntries || currentReviewerEntries.length === 0) {
        return;
      }

      // Find the latest review entry by timestamp
      const latestEntry = currentReviewerEntries.reduce((latest, current) => {
        return new Date(current.reviewSubmittedAt) >
          new Date(latest.reviewSubmittedAt)
          ? current
          : latest;
      }, currentReviewerEntries[0]);

      // Update the confirmed answers based on the latest entry
      state.selectedGroundTruthData.fields =
        state.selectedGroundTruthData.fields.map((field: GroundTruthField) => ({
          ...field,
          confirmedAnswer:
            latestEntry.fields.find(
              (fieldEntry: ReviewFieldEntry) =>
                fieldEntry.fieldName === field.fieldName,
            )?.answer || field.confirmedAnswer,
        }));
    },
    sortAndFilterGroundTruthDataListView: (state, { payload }) => {
      // Only filter for multi-person review
      if (payload.model.requiredReviewerCount > 1) {
        state.groundTruthDataListView = state.groundTruthDataListView.filter(
          (item) => {
            const hasReachedRequiredCount =
              item.reviewContributors?.length >=
              payload.model.requiredReviewerCount;
            return (
              !hasReachedRequiredCount ||
              (hasReachedRequiredCount &&
                item.reviewContributors?.includes(payload.alias))
            );
          },
        );
      }

      // Sort the list
      state.groundTruthDataListView.sort((a, b) => {
        const isReviewed = (item: GroundTruthDataListView) =>
          item.status === ReviewStatus.REVIEWED ||
          item.reviewContributors?.includes(payload.alias);
        return isReviewed(a) === isReviewed(b)
          ? a.sourceId.localeCompare(b.sourceId)
          : isReviewed(a)
            ? 1
            : -1;
      });

      // Calculate reviewed count
      state.groundTruthDataReviewedCount =
        payload.model.requiredReviewerCount > 1
          ? state.groundTruthDataListView.filter((item) =>
              item.reviewContributors?.includes(payload.alias),
            ).length
          : state.groundTruthDataListView.filter(
              (item) => item.status === ReviewStatus.REVIEWED,
            ).length;
    },
    resetListViewPage: (state) => {
      state.getListViewLoading = "idle";
      state.getGroundTruthDataLoading = "idle";
      state.getGroundTruthDataStatistics = "idle";
      state.updateGroundTruthDataLoading = "idle";
      state.translationLoading = "idle";
      state.translation = "";
      state.showTranslation = false;
    },
    resetGroundTruthReviewPage: (state) => {
      state.getGroundTruthDataLoading = "idle";
      state.getGroundTruthDataStatistics = "idle";
      state.updateGroundTruthDataLoading = "idle";
      state.translationLoading = "idle";
      state.translation = "";
      state.showTranslation = false;
      state.pageLoadTimestamp = generateTimestamp();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroundTruthDataListView.pending, (state) => {
      state.getListViewLoading = "pending";
      state.groundTruthDataTotalCount = 0;
      state.groundTruthDataReviewedCount = 0;
    });
    builder.addCase(
      getGroundTruthDataListView.fulfilled,
      (state, { payload }) => {
        state.getListViewLoading = "fulfilled";
        state.groundTruthDataListView = payload;
        state.groundTruthDataTotalCount = state.groundTruthDataListView.length;
      },
    );
    builder.addCase(getGroundTruthDataListView.rejected, (state) => {
      state.getListViewLoading = "rejected";
      state.groundTruthDataTotalCount = 0;
      state.groundTruthDataReviewedCount = 0;
    });

    builder.addCase(updateGroundTruthData.pending, (state) => {
      state.updateGroundTruthDataLoading = "pending";
    });
    builder.addCase(updateGroundTruthData.fulfilled, (state, { payload }) => {
      state.updateGroundTruthDataLoading = "fulfilled";
      const index = state.groundTruthDataListView.findIndex(
        (groundTruthDataListView: GroundTruthDataListView) =>
          groundTruthDataListView.sourceId === payload.sourceId,
      );
      if (index !== -1) {
        state.groundTruthDataListView[index].status = payload.status;
      }
    });
    builder.addCase(updateGroundTruthData.rejected, (state) => {
      state.updateGroundTruthDataLoading = "rejected";
    });
    builder.addCase(addReviewEntry.fulfilled, (state, { payload }) => {
      state.updateGroundTruthDataLoading = "fulfilled";
      const index = state.groundTruthDataListView.findIndex(
        (groundTruthDataListView: GroundTruthDataListView) =>
          groundTruthDataListView.sourceId === payload.sourceId,
      );
      if (index !== -1) {
        state.groundTruthDataListView[index].status = payload.status;
      }
    });
    builder.addCase(addReviewEntry.rejected, (state) => {
      state.updateGroundTruthDataLoading = "rejected";
    });
    builder.addCase(getGroundTruthData.pending, (state) => {
      state.getGroundTruthDataLoading = "pending";
    });
    builder.addCase(getGroundTruthData.fulfilled, (state, { payload }) => {
      state.getGroundTruthDataLoading = "fulfilled";
      state.selectedGroundTruthData =
        setLLMAnswerForUnreviewedGroundTruthData(payload);
      state.selectedGroundTruthData.fields =
        state.selectedGroundTruthData.fields.map((field) => {
          if (
            typeof field.confirmedAnswer === "string" &&
            field.confirmedAnswer.includes("|")
          ) {
            return {
              ...field,
              confirmedAnswer: field.confirmedAnswer
                .split("|")
                .map((answer) => answer.trim())
                .filter((answer) => answer !== ""), // Remove empty strings
            };
          }
          return field;
        });
      state.showUserAnswerColumn = state.selectedGroundTruthData.fields.some(
        (field) => field.userAnswer.trim() !== "",
      );
      state.showLLMAnswerColumn = state.selectedGroundTruthData.fields.some(
        (field) => field.llmAnswer.trim() !== "",
      );
      state.pageLoadTimestamp = generateTimestamp();
    });
    builder.addCase(getGroundTruthData.rejected, (state) => {
      state.getGroundTruthDataLoading = "rejected";
    });
    builder.addCase(
      downloadGroundTruthsInCSV.fulfilled,
      (state, { payload }) => {
        if (payload) {
          // download file
          downloadFile(createFileUrl(payload, "text/csv"), "ground_truths.csv");
        }
      },
    );
    builder.addCase(translate.pending, (state) => {
      state.translationLoading = "pending";
      state.translation = "";
      state.showTranslation = false;
    });
    builder.addCase(translate.fulfilled, (state, { payload }) => {
      state.translationLoading = "fulfilled";
      state.translation = payload;
      state.showTranslation = true;
    });
    builder.addCase(translate.rejected, (state) => {
      state.translationLoading = "rejected";
      state.showTranslation = false;
    });
  },
});

export const {
  setFieldConfirmedAnswer,
  setFieldIndex,
  setCurrentPage,
  setIsBlind,
  setItemPerPage,
  resetGroundTruthReviewPage,
  resetListViewPage,
  setShowTranslation,
  setTranslation,
  updateConfirmedAnswersFromHistory,
  sortAndFilterGroundTruthDataListView,
} = actions;

export default reducer;
